import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Input} from 'antd';
import JoditEditor from 'jodit-react';
import {LockOutlined, UnlockOutlined} from '@ant-design/icons';

const NoteForm = ({initialData, onSubmit, onCancel}) => {
    const [form] = Form.useForm();
    const [isLocked, setIsLocked] = useState(initialData && initialData.recommend === -1);
    const editorRef = useRef(null);

    useEffect(() => {
        setIsLocked(initialData && initialData.recommend === -1);
    }, [initialData]);

    const handleFinish = (values) => {
        debugger
        let content = editorRef.current?.value || '';
        const initScript = initialData?.content?.match(/<script\b[^>]*>[\s\S]*?<\/script>/i)?.[0] || null;
        const contentScript = content?.match(/<script\b[^>]*>[\s\S]*?<\/script>/i)?.[0] || null;
        if (initScript && !contentScript) {
            content += initScript;
        }
        const finalValues = {...values, content, recommend: isLocked ? -1 : 0};
        onSubmit({...initialData, ...finalValues});
    };

    const handleLockToggle = () => {
        setIsLocked(prev => !prev);
    };

    const joditConfig = {
        minHeight: window.innerHeight - 145,
        readonly: false
    };

    return (
        <>
            <Form
                form={form}
                layout="horizontal"
                initialValues={initialData}
                onFinish={handleFinish}
                style={{position: 'relative'}}
            >
                <Form.Item
                    name="parent"
                    style={{display: 'none'}}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="title"
                    rules={[{required: true, message: 'Please enter the title'}]}
                >
                    <Input/>
                </Form.Item>

                <div>
                    <JoditEditor
                        ref={editorRef}
                        value={initialData?.content || ''}  // 这里初始化内容
                        config={joditConfig}
                    />
                </div>

                <div style={{
                    position: 'fixed',
                    bottom: '60px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000
                }}>
                    <Button type="primary" htmlType="submit" style={{marginRight: '8px'}}>
                        Save
                    </Button>
                    <Button type="dashed" onClick={onCancel}>
                        Cancel
                    </Button>
                </div>

                <Button
                    type="text"
                    icon={isLocked ? <LockOutlined/> : <UnlockOutlined/>}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        zIndex: 1000,
                        fontSize: '24px',
                    }}
                    onClick={handleLockToggle}
                />
            </Form>
        </>
    );
};

export default NoteForm;