import React, {useEffect} from 'react';
import {FloatButton} from 'antd';

const NoteDetail = ({note}) => {
    useEffect(() => {
        if (note?.recommend === -1) {
            const scripts = document.querySelectorAll('script');
            scripts.forEach((script) => {
                const newScript = document.createElement('script');
                newScript.textContent = script.textContent;
                document.body.appendChild(newScript);
                document.body.removeChild(newScript);
            });
        }
    }, [note?.content]);

    return (
        <div>
            <h2
                style={{
                    padding: '8px 0',
                    textAlign: 'center',
                    fontSize: '24px',
                    margin: 0,
                    background: '#fff', // 与内容背景色一致
                }}
            >
                {note?.title || ''}
            </h2>

            <div
                style={{
                    padding: '24px 0',
                    background: '#fff',
                    minHeight: 500,
                }}
                dangerouslySetInnerHTML={{__html: note?.content || 'Please select a note to view the details'}}
            />

            <FloatButton.BackTop
                style={{bottom: 20, right: 20}}
            />
        </div>
    );
};

export default NoteDetail;